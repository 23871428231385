<template>
  <b-container v-if="renderPage">
    <b-overlay
      :show="isLoading"
      :opacity="1"
      variant="light"
      rounded="sm"
    >
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <strong class="mr-1">Consultando, Aguarde...</strong>
          <b-icon
            icon="stopwatch"
            font-scale="3"
            animation="cylon"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
          <b-spinner
            type="grow"
            variant="primary"
          />
          <b-spinner
            small
            type="grow"
            variant="primary"
          />
        </div>
      </template>
      <b-card
        title="Busca SCR"
      >
        <validation-observer ref="simpleRules">
          <b-form
            novalidate
            class="needs-validation"
            @submit.prevent
          >
            <b-row>
              <b-col
                sm="12"
                lg="6"
                md="6"
              >
                <b-form-group
                  label="CPF/CNPJ"
                  label-for="document"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CPF/CNPJ"
                    rules="required|tax_id"
                  >
                    <b-input-group :class="errors.length === 0 ? 'default-color' : 'is-invalid'">
                      <b-input-group-prepend
                        :state="errors.length > 0 ? false : null"
                        is-text
                      >
                        <feather-icon icon="EditIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="document"
                        v-model="customerTaxId"
                        v-mask="[
                          '###.###.###-##',
                          '##.###.###/####-##',
                        ]"
                        placeholder="CPF/CNPJ a ser consultado"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                lg="6"
                md="6"
              >
                <b-form-group
                  label="CPF do usuário"
                  label-for="document_cpf"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CPF do Usuário"
                    rules="required|tax_id"
                  >
                    <b-input-group :class="errors.length === 0 ? 'default-color' : 'is-invalid'">
                      <b-input-group-prepend
                        is-text
                      >
                        <feather-icon icon="EditIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="document_cpf"
                        v-model="userTaxId"
                        v-mask="[
                          '###.###.###-##',
                          '##.###.###/####-##',
                        ]"
                        placeholder="Informe seu Documento"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                lg="6"
                md="6"
              >
                <b-form-group
                  label="Mês"
                  label-for="v-month"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="month"
                    rules="required"
                  >
                    <b-form-select
                      v-model="selectedMonth"
                      :options="monthOptions"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                lg="6"
                md="6"
              >
                <b-form-group
                  label="Ano"
                  label-for="v-year"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Ano"
                    rules="required"
                  >
                    <b-form-select
                      v-model="selectedYear"
                      :options="yearOptions"
                      :state="errors.length > 0 ? false : null"
                    >
                      <template v-slot:first>
                        <b-form-select-option
                          disabled
                          :value="null"
                        >
                          Selecione o ano
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <div class="col text-center mt-2">
                <b-button
                  variant="primary"
                  @click="validationForm"
                >
                  Buscar
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </div>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
      <b-card
        v-if="showTableSCR"
        title="Informações"
      >
        <b-row>
          <b-table
            responsive
            :fields="informationTabelFields"
            :items="customerDetails"
            hover
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong> Carregando...</strong>
              </div>
            </template>
          </b-table>
        </b-row>
        <b-row>
          <b-table
            responsive
            :fields="informationTabelFieldsExtra"
            :items="customerDetailsExtra"
            hover
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong> Carregando...</strong>
              </div>
            </template>

            <template #cell(totalResponsibility)="data">
              {{ data.item.totalResponsibility | value }}
            </template>
            <template #cell(releaseCredit)="data">
              {{ data.item.releaseCredit | value }}
            </template>
            <template #cell(limitsCredit)="data">
              {{ data.item.limitsCredit | value }}
            </template>
            <template #cell(indirectRisk)="data">
              {{ data.item.indirectRisk | value }}
            </template>
            <template #cell(totalRisk)="data">
              {{ data.item.totalRisk | value }}
            </template>
          </b-table>
        </b-row>
      </b-card>
      <b-card
        v-if="showTableSCR"
        title="Detalhes"
      >
        <b-row>
          <b-table
            responsive
            :fields="detailsTableFields"
            :items="operationDetails"
            hover
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong> Carregando...</strong>
              </div>
            </template>
          </b-table>
        </b-row>
        <b-row>
          <b-table
            responsive
            :fields="detailsTableFieldsExtra"
            :items="fileDetails"
            hover
            :busy="isLoading"
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle" />
                <strong> Carregando...</strong>
              </div>
            </template>
          </b-table>
        </b-row>
      </b-card>
    </b-overlay>
  </b-container>
</template>
<script>
import {
    BButton,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BCol,
    BRow,
    BCard,
    BContainer,
    BForm,
    BTable,
    BFormSelect,
    BFormSelectOption,
    BSpinner,
    BOverlay,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import Ripple from 'vue-ripple-directive';
import 'animate.css';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import ScrService from '@/service/scr';

import { formatDate } from '@/utils';

const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});
const luxon = require('luxon').DateTime;

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BButton,
        BSpinner,
        BInputGroup,
        BFormGroup,
        BFormInput,
        BInputGroupPrepend,
        BCol,
        BRow,
        BCard,
        BContainer,
        BForm,
        BTable,
        BFormSelect,
        BFormSelectOption,
        BOverlay,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            renderPage: true,
            required,
            borderColor1: null,
            borderColor2: null,
            informationTabelFields: [
                { key: 'portfolioDue', label: 'A vencer' },
                { key: 'expiredPortfolio', label: 'Vencido' },
                { key: 'loss', label: 'Prejuizo' },
                { key: 'creditPortfolio', label: 'Carteira Crédito' },
                { key: 'transfers', label: 'Repasses interfinanceiros' },
                { key: 'coobligations', label: 'Coobrigações' },
            ],
            informationTabelFieldsExtra: [
                { key: 'totalResponsibility', label: 'Responsabilidade Total' },
                { key: 'releaseCredit', label: 'Crédito a liberar' },
                { key: 'limitsCredit', label: 'Limite de crédito' },
                { key: 'indirectRisk', label: 'Risco Indireto' },
                { key: 'totalRisk', label: 'Risco Total' },
            ],
            detailsTableFields: [
                { key: 'item', label: 'Descrição' },
                { key: 'values', label: 'Valor' },
            ],
            detailsTableFieldsExtra: [
                { key: 'type', label: 'Tipo' },
                { key: 'domain', label: 'Dominio' },
                { key: 'subdomain', label: 'Sub-Domínio' },
                { key: 'amount', label: 'Valor' },
            ],
            monthOptions: [
                { text: 'Selecione o mês', value: null },
                { text: 'Janeiro', value: '01' },
                { text: 'Fevereiro', value: '02' },
                { text: 'Março', value: '03' },
                { text: 'Abril', value: '04' },
                { text: 'Maio', value: '05' },
                { text: 'Junho', value: '06' },
                { text: 'Julho', value: '07' },
                { text: 'Agosto', value: '08' },
                { text: 'Setembro', value: '09' },
                { text: 'Outubro', value: '10' },
                { text: 'Novembro', value: '11' },
                { text: 'Dezembro', value: '12' },
            ],
            selectedMonth: null,
            selectedYear: null,
            customerTaxId: null,
            userTaxId: null,
            isLoading: false,
            customerDetails: [],
            customerDetailsExtra: [],
            operationDetails: [],
            fileDetails: [],
            showTableSCR: false,
            userId: null,
        };
    },
    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
    computed: {
        yearOptions() {
            const date = new Date();

            const yearArray = [];
            let year = date.getFullYear();

            while (year !== 1999) {
                yearArray.push(year);
                year -= 1;
            }

            return yearArray.reverse();
        },
    },
    methods: {
        async scrConsult() {
            const removeSymbolsTaxId = document => (document ? document.replace(/[^a-zA-Z0-9]/g, '') : undefined);
            this.isLoading = true;
            const fileDetails = [];
            const objRequest = {
                tax_id: removeSymbolsTaxId(this.customerTaxId),
                month: this.selectedMonth,
                year: this.selectedYear,
                user_id: this.userId,
            };

            const information = await ScrService.scr(objRequest);

            const error = () => {
                this.customerDetails = [];
                this.customerDetailsExtra = [];
                this.operationDetails = [];
                this.fileDetails = [];
                this.showTableSCR = false;
                const msg = `Não há informações referentes a data ${this.selectedMonth}/${this.selectedYear}`;
                this.error(msg, 'InfoIcon');
                this.isLoading = false;
            };

            if (!information) {
                error();
                this.isLoading = false;
                return;
            }

            if (!information.Erro) {
                const customerDetails = [
                    {
                        portfolioDue: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencer,
                        ),
                        expiredPortfolio: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencido,
                        ),
                        loss: formatter.format(
                            information.ResumoDoClienteTraduzido.Prejuizo,
                        ),
                        creditPortfolio: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiradeCredito,
                        ),
                        transfers: formatter.format(
                            information.ResumoDoClienteTraduzido.Repasses,
                        ),
                        coobligations: formatter.format(
                            information.ResumoDoClienteTraduzido.Coobrigacoes,
                        ),
                    },
                ];

                const {
                    ResponsabilidadeTotal: totalResponsibility,
                    CreditosaLiberar: releaseCredit,
                    LimitesdeCredito: limitsCredit,
                    RiscoIndiretoVendor: indirectRisk,
                    RiscoTotal: totalRisk,
                } = information.ResumoDoClienteTraduzido;

                const customerDetailsExtra = [
                    {
                        totalResponsibility,
                        releaseCredit,
                        limitsCredit,
                        indirectRisk,
                        totalRisk,
                    },
                ];

                const operationDetails = [
                    {
                        item: 'Início do Relacionamento',
                        values: formatDate(information.ResumoDoClienteTraduzido.DtInicioRelacionamento),
                    },
                    {
                        item: 'Quantidade de Intituições Financeiras',
                        values: information.ResumoDoClienteTraduzido.QtdeInstituicoes,
                    },
                    {
                        item: 'Quantidade de Operações',
                        values: information.ResumoDoClienteTraduzido.QtdeOperacoes,
                    },
                    {
                        item: 'Quantidade de Operações em Discordancia',
                        values:
              information.ResumoDoClienteTraduzido.QtdeOperacoesDiscordancia,
                    },
                    {
                        item: 'Valor de Operações em Discordancia',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.VlrOperacoesDiscordancia,
                        ),
                    },
                    {
                        item: 'Quantidade de Operações Sob Judice',
                        values: information.ResumoDoClienteTraduzido.QtdeOperacoesSobJudice,
                    },
                    {
                        item: 'Valor de Operações Sob Judice',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.VlrOperacoesSobJudice,
                        ),
                    },
                    {
                        item: 'Carteira a Vencer Até 30 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido
                                .CarteiraVencerAte30diasVencidosAte14dias,
                        ),
                    },
                    {
                        item: 'Carteira a Vencer de 31 dias a 60 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencer31a60dias,
                        ),
                    },
                    {
                        item: 'Carteira a Vencer de 61 dias a 90 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencer61a90dias,
                        ),
                    },
                    {
                        item: 'Carteira a Vencer de 91 dias a 180 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencer91a180dias,
                        ),
                    },
                    {
                        item: 'Carteira a Vencer de 181 dias a 360 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencer181a360dias,
                        ),
                    },
                    {
                        item: 'Carteira a Vencer Acima de 360 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencerAcima360dias,
                        ),
                    },
                    {
                        item: 'Carteira a Vencer Prazo Indeterminado',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido
                                .CarteiraVencerPrazoIndeterminado,
                        ),
                    },
                    {
                        item: 'Carteira Vencido Até 30 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencido15a30dias,
                        ),
                    },
                    {
                        item: 'Carteira Vencido de 31 dias a 60 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencido31a60dias,
                        ),
                    },
                    {
                        item: 'Carteira Vencido de 61 dias a 90 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencido61a90dias,
                        ),
                    },
                    {
                        item: 'Carteira Vencido de 91 dias a 180 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencido91a180dias,
                        ),
                    },
                    {
                        item: 'Carteira Vencido de 181 dias a 360 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencido181a360dias,
                        ),
                    },
                    {
                        item: 'Carteira Vencido Acima de 360 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.CarteiraVencidoAcima360dias,
                        ),
                    },
                    {
                        item: 'Prejuizo até 360 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.PrejuizoAte12meses,
                        ),
                    },
                    {
                        item: 'Prejuizo Acima de 360 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.PrejuizoAcima12meses,
                        ),
                    },
                    {
                        item: 'Limites de Crédito até 360 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.PrejuizoAte12meses,
                        ),
                    },
                    {
                        item: 'Limites de Crédito Acima 360 dias',
                        values: formatter.format(
                            information.ResumoDoClienteTraduzido.PrejuizoAcima12meses,
                        ),
                    },
                ];
                const transactions = information.ResumoModalidade;
                const filteredMaxiPago = transactions.filter(maxipagoList => maxipagoList.valorVencimento !== 0);

                filteredMaxiPago.forEach(param => {
                    const {
                        tipo, dominio, subdominio, valorVencimento,
                    } = param;

                    const details = {
                        type: tipo,
                        domain: dominio,
                        subdomain: subdominio,
                        amount: formatter.format(valorVencimento),
                    };
                    fileDetails.push({ ...details });
                });

                this.showTableSCR = true;
                this.customerDetails = customerDetails;
                this.customerDetailsExtra = customerDetailsExtra;
                this.operationDetails = operationDetails;
                this.fileDetails = fileDetails;
            } else {
                error();
            }

            this.isLoading = false;
        },
        async checkPermission() {
            const userTaxId = this.userTaxId.replace(/[^a-zA-Z0-9]/g, '');
            const response = await ScrService.verifyPermission(userTaxId);
            const { userId, userDocument } = response.data.data;

            if (userTaxId === userDocument) {
                this.userId = userId;
                await this.scrConsult();
            } else {
                this.isLoading = false;
                const msg = `O usuário ${this.userTaxId}, não tem permissão para Acesso`;
                this.error(msg, 'InfoIcon');
            }
        },
        async validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.isLoading = !this.isLoading;
                    this.checkPermission();
                }
            });
        },
        async error(msg, icon) {
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: msg,
                        icon,
                        variant: 'danger',
                    },
                },
                {
                    position: 'top-center',
                },
                {
                    timeout: 5000,
                },
            );
        },
        borderColorFeedback1(event) {
            const { className } = event.explicitOriginalTarget;
            if (className.includes('is-invalid')) { this.borderColor1 = 'is-invalid'; } else { this.borderColor1 = ''; }
        },
        borderColorFeedback2(event) {
            const { className } = event.explicitOriginalTarget;
            if (className.includes('is-invalid')) { this.borderColor2 = 'is-invalid'; } else { this.borderColor2 = 'is-valid'; }
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/scr-style.scss";

.default-color {
  border-color: #2E2C6C;
}
</style>
