import Thoth from './base/thoth';
import methods from '@/enums/methods';

class ScrService extends Thoth {
    async scr({
        tax_id, month, year, user_id,
    }) {
        const response = await this.api.post('scr/consult', {
            tax_id, month, year, user_id,
        });

        return response.data;
    }

    index({ tax_id }) {
        return this.api.get('/scr', {
            params: {
                tax_id,
            },
        });
    }

    async verifyPermission(taxId) {
        return this.fetch({
            url: 'scr/tracking/show',
            params: {
                document: taxId,
            },
            maxBodyLength: Infinity,
        });
    }

    async registerAccessScr(data) {
        return this.fetch({
            method: methods.POST,
            url: 'scr/tracking/store',
            data,
        });
    }
}

export default new ScrService();
